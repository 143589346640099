@import "../../../styles/base/variables";
.sign-in {
  a {
    color: $blue-primary;
    text-decoration: none;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  box-shadow: none;
  border: 1px solid $border-primary;
  border-radius: $border-radius-card;
  @include media-up(sm) {
    padding: 90px 50px;
    width: 460px;
    min-height: 290px;
  }
  &__header {
    text-align: center;
  }
  &__header-title {
    font-weight: 400;
    font-size: 35px;
    text-align: center;
    line-height: 1;
    margin-bottom: 10px;
  }
  &__header-text {
    font-size: 1rem;
  }
  &__btn {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .mat-button-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  &__btn-button {
    padding: 13px 20px;
    font-size: 18px;

  }
  &__btn-image {
    display: flex;
    align-items: center;
    width: 20px;
    margin-right: 10px;

    img {
      width: 100%;
    }
  }
  &__btn-text {
    line-height: 1;
    flex-grow: 1;
    font-weight: 500;
    color: $black-secondary;
  }
  &__text {
    margin-top: 0.5rem;
    padding: 1rem;
    color: $black-secondary;
    p {
      text-align: center;
    }
  }
  &__form {
    width: 100%;
  }
  &__form-field {
    width: 100%;
    border: 1px solid $border-primary;
    border-radius: $border-radius-card;
  }
  .mat-form-field-underline {
    display: none;
  }
  .mat-form-field-flex {
    padding-left: 20px;
    padding-right: 20px;
  }
  .mat-form-field-label-wrapper {
    font-size: 16px;
    top: -12px;
    overflow: visible;
  }
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: scale(0.75) translateY(-39px);
    width: fit-content;
    background-color: white;
    padding-left: 5px;
    padding-right: 5px;
  }
  &__forgot-link {
    font-weight: 500;
    margin-top: 8px;
  }
  &__form-info {
    margin-top: 2rem;
  }
  &__btn-next {
    font-size: 14px;
    font-weight: 400;
    padding: 0.5rem 2rem;
    cursor: pointer;
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }
}
