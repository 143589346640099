@import "/src/styles/base/variables";

.dummy,
.dummy * {
  outline: 1px dashed red;
}

// reset
.mat-typography {
  font-size: 16px !important;
  letter-spacing: normal;
}

.mat-drawer-container {
  color: $text !important;
}

html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  height: 100%;

  .rtl {
    * {
      direction: rtl !important;
    }
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  scroll-behavior: smooth;
}

// print helper
@media print {
  .noprint,
  .noprint * {
    display: none !important;
  }
}

// make layout full height, to show footer on bottom
.mat-drawer-content {
  display: flex !important;
  height: 100%;
  flex-direction: column;

  .container-app {
    flex-grow: 1;
  }
}

.title {
  font: 400 62px/62px Roboto;
  display: inline-block;
  color: #202124;
}

.subtitle-1 {
  font-size: 18px;
}

.subtitle-2 {
  font-size: 26px;
}

.subtitle-3 {
  font-size: 36px;
}

.subtitle-1,
.subtitle-2,
.subtitle-3 {
  font-weight: 500;
}

.container {
  width: 100%;
  max-width: 1290px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container-md {
  @include media-up(md) {
    width: 100%;
    max-width: 1290px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.content-wrapper {
  padding-top: 40px;
  padding-bottom: 40px;
}

.link-primary {
  text-decoration: none;
  color: $blue-primary;
  font-weight: 400;
  display: flex;
  align-items: center;

  img:last-child {
    margin-left: 5px;
  }
}

// material buttons
.mat-button {
  font-size: 18px !important;
  font-weight: 400 !important;
  padding: 20px 60px !important;
  line-height: 1 !important;

  &.mat-primary {
    background-color: $blue-primary !important;
    color: white !important;
  }
}

.mat-stroked-button {
  font-size: 18px !important;
  font-weight: 400 !important;
  padding: 20px 60px !important;
  line-height: 1 !important;

  &.mat-primary {
    border-color: $blue-primary !important;
    color: $blue-primary !important;
  }
}
