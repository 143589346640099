/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
*{
  font-weight: 500;
  font-family: Roboto;
  letter-spacing: 0.1px;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale ;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 16px;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
  overflow-x: hidden;
}
ul, ol {
  display: block;
  list-style: disc outside none;
  margin: 1em 0;
  padding: 0 0 0 40px;
}
ol {
  list-style-type: decimal;
}

li {
  display: list-item;
}

ul ul, ol ul {
  list-style-type: circle;
  margin-left: 15px;
}
ol ol, ul ol {
  list-style-type: lower-latin;
  margin-left: 15px;
}


em, i {
  font-style: italic !important;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
strong {
  font-weight: bold;
}
