@import "../../../styles/base/variables";

.toolbar {
  background-color: transparent;
  padding: 60px 2.5rem !important;
  border-bottom: 1px solid #d7d7d7;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 80px;

  .toolbar-logo {
    display: flex;
    align-items: center;
    flex-direction: row;

    .google-logo {
      width: 225px;
      cursor: pointer;
    }

    .union-image {
      height: 40px;
      margin-left: 30px;
      width: auto;;
    }
  }

  @include media-up(md) {
    padding: 2.5rem;
  }

  .header-icons {
    display: flex;
    justify-content: space-between;

    button {
      margin-left: 10px;
    }

    .align-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ic-burger {
      font-size: 35px;
      @include media-up(md) {
        display: none;
      }
    }

    .ic-account {
      color: #4285f4;
      font-size: 35px;
      margin: 0 auto;
    }
  }
}

@media only screen and (max-width: 760px) {
  .union-image {
    display: none;
  }
}
